import { useLocation } from "@reach/router";
import {
  Button,
  Form,
  Input,
  message,
  Pagination,
  Popconfirm,
  Spin,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { forEach, get, isEmpty, map } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { feathers } from "../../../api/feathers_rest";
import { updateActiveId } from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
// import getDirectRewards from "../../../newApi/rewardExplore/getDirectRewards";
import { DeleteOutlined } from "@ant-design/icons";
import queryString from "query-string";
import { not_foundIcon } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import deleteCampaign from "../../../newApi/campaign/deleteCampaign";
import getCampaigns from "../../../newApi/campaign/getCampaigns";
import getProfiles from "../../../newApi/profile/getProfiles";

const PAGE_SIZE = 10;
const roundingDecimal = 2;
const rounding = false;
//let displayRobot = [];

const dummy = [
  {
    createdAt: new Date(),
    content: "HiHi ad asd adas asa da dasdasd as",
    link: "link1",
  },
  {
    createdAt: new Date(),
    content: "HiHi2 ad asd adas asa da dasdasd as",
    link: "link2",
  },
  {
    createdAt: new Date(),
    content: "HiHi3 ad asd adas asa da dasdasd as",
    link: "link3",
  },
  {
    createdAt: new Date(),
    content: "HiHi4 ad asd adas asa da dasdasd as",
    link: "link4",
  },
  {
    createdAt: new Date(),
    content: "HiHi ad asd adas asa da dasdasd as",
    link: "link1",
  },
];

// markup
const DirectReward = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [profile, setProfile] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [view, setView] = useState(false);
  let accessKey = get(props.user, "accessKey");
  //let profile = get(props.user.user, "profile");
  const user = get(props, "user.user");

  useEffect(() => {
    let query = queryString.parse(location.search);
    let action = get(query, "action");
    if (action === "view") {
      setView(true);
    }
    getData((page - 1) * PAGE_SIZE);
    getProfile();
  }, []);

  useEffect(() => {
    getData((page - 1) * PAGE_SIZE);
  }, [page]);

  function getData(skip) {
    setLoading(true);
    let startDate = new Date();
    startDate = startDate.setDate(startDate.getDate() - 2);
    let endDate = new Date();
    startDate = moment(startDate).format("YYYY-MM-DD");
    endDate = moment(endDate).format("YYYY-MM-DD");
    startDate = moment(startDate + " " + "00:00");
    endDate = moment(endDate + " " + "23:59");

    getCampaigns(
      PAGE_SIZE,
      skip,
      {
        subscriber: get(user, "profile._id"),
        //   createdDateFrom: startDate.toDate(),
        //   createdDateTo: endDate.toDate(),
      },
      accessKey
    )
      .then((res) => {
        // console.log("Res", res);
        let data = get(res, "data");
        setTotal(get(res, "total"));
        setDataSource(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function getProfile() {
    getProfiles(1, 0, { _id: get(user, "profile._id") })
      .then((res) => {
        setProfile(get(res, "data[0]") || {});
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleSubmit() {
    form
      .validateFields()
      .then((values) => {
        // console.log(values);
        feathers("campaign", accessKey)
          .create({
            ...values,
            profileId: get(user, "profile._id"),
            createdBy: get(user, "_id"),
          })
          .then((res) => {
            // console.log("Res", res);
            message.success(t("success"));
            form.resetFields();
            getData((page - 1) * PAGE_SIZE);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        forEach(get(err, "errorFields"), (item) => {
          message.error(get(item, "errors[0]"));
          setLoading(false);
        });
      });
  }

  function handleDelete(item) {
    // console.log(item);
    deleteCampaign({ _id: get(item, "_id") })
      .then((res) => {
        // console.log("Res delete", res);
        message.success(t("success"));
        getData((page - 1) * PAGE_SIZE);
      }, accessKey)
      .catch((err) => {
        console.log(err);
      });
  }

  const _renderForm = () => {
    return (
      <div>
        <div className=" mb-2 font-bold">
          {t("campaignDesc", sourceKey.home)}
        </div>
        <Form form={form}>
          <div className="mx-2">
            <Form.Item
              name="content"
              initialValue=""
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: (
                    <React.Fragment>
                      {t("descRequired", sourceKey.home)}
                    </React.Fragment>
                  ),
                },
              ]}
            >
              <Input.TextArea
                placeholder=""
                autoComplete="off"
                className={"input-border"}
              />
            </Form.Item>
            <Form.Item
              name="link"
              initialValue=""
              style={{ margin: 0, marginTop: 6 }}
              //   rules={[
              //     {
              //       required: true,
              //       message: (
              //         <React.Fragment>

              //          {t("descRequired")}
              //         </React.Fragment>
              //       ),
              //     },
              //   ]}
            >
              <Input
                placeholder={t("pasteLink", sourceKey.home)}
                autoComplete="off"
                className={"input-border"}
              />
            </Form.Item>
            <div className="mt-3">
              <Button
                loading={loading}
                className="main-button-color"
                style={{ width: "100%" }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t("submit")}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  const _renderList = () => {
    return (
      <div>
        <div className="pl-2">{t("history", sourceKey.home)}</div>
        <div className="greybox-bg-color mx-4 rounded-3xl py-2 mt-1.5">
          {isEmpty(dataSource) && (
            <div className="items-center justify-center mt-10 ">
              <img
                src={not_foundIcon}
                className=" object-contain h-full w-full"
                style={{ height: "15vh" }}
              />

              <br></br>
              <div className="flex justify-center items-center">
                <div
                  className="flex justify-center items-center font-black text-lg text-center mb-2 text-gray-400"
                  style={{ width: 310 }}
                >
                  {t("noRecords", sourceKey.highFrequency)}
                </div>
              </div>
            </div>
          )}
          {map(dataSource, (item, index) => {
            return (
              <div
                key={index}
                className=" pb-2 "
                // style={{ overflowWrap: "break-word", width: "100%" }}
              >
                <div className="px-2 mx-2 border-b-2 border-b-white">
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      {get(item, "createdDate")
                        ? moment(get(item, "createdDate")).format(
                            "DD/MM/YYYY hh:mm:ss A"
                          )
                        : ""}
                    </div>
                    <div>
                      {get(item, "profileId") === get(user, "profile._id") ? (
                        <Popconfirm
                          title={<>{t("areYouSure", sourceKey.pairing)}</>}
                          onConfirm={() => {
                            // console.log("co");
                            handleDelete(item);
                          }}
                          placement="leftTop"
                        >
                          <DeleteOutlined
                            style={{ color: "red", fontSize: 17 }}
                          />
                        </Popconfirm>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="flex justify-start items-center font-semibold ">
                    {get(item, "content")}
                  </div>
                  <div className="flex justify-start items-center">
                    {/* <span className={`inline-block  font-semibold`}>
                      {t("link", sourceKey.home)}
                    </span>
                    <span className="inline-block mx-2">:</span> */}
                    <span className="text-xs sm:text-sm">
                      <a
                        href={get(item, "link")}
                        className="break-words text-white"
                      >
                        {get(item, "link")}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            showBack={true}
            suffix={
              <React.Fragment>
                <div className="justify-end flex items-center">
                  {t("campaign", sourceKey.home)}
                </div>
              </React.Fragment>
            }
          >
            <div
              className="mx-2"
              // style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
            >
              {get(profile, "enableInsertCampaign") === 1 && view === false && (
                <div className="">{_renderForm()}</div>
              )}
              <div className="mt-4"> {_renderList()} </div>

              <div className="flex justify-end items-center my-5 mx-5">
                <span className="inline-block ">
                  <Pagination
                    simple
                    size="small"
                    total={total}
                    pageSize={PAGE_SIZE}
                    current={page}
                    showSizeChanger={false}
                    showQuickJumper={false}
                    responsive={true}
                    onChange={(page) => {
                      setPage(page);
                    }}
                    // style={{ backgroundColor: "transparent" }}
                  />
                </span>
              </div>
            </div>
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  updateActiveId,
};
export default connect(mapStateToProps, mapDispatchToProps)(DirectReward);
