import { campaignPrefix } from ".";
import { apiUrl, _base_axios_post } from "..";

export default function deleteCampaign(query = {}, accessKey = "") {
  return _base_axios_post(
    `${apiUrl}/${campaignPrefix}/deleteCampaign`,
    query,
    accessKey
  );
}
